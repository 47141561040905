<script setup lang="ts">
import CustomSelector from '@/components/custom_selector/CustomSelector.vue';
import { StateUtils } from '@/utils/state_utils';
import { ref } from 'vue';

const stateSelector = ref<InstanceType<typeof CustomSelector>>();

defineProps<{
  stateError?: string;
}>();

defineEmits<{
  (e: 'change', state: string | null): void
}>();

defineExpose({
  setSelected(stateCode: string) {
    const fullNameIndex = StateUtils.findStateIndexByFullName(stateCode);
    const codeIndex = StateUtils.findStateIndexByCode(stateCode);
    const finalIndex = Math.max(fullNameIndex, codeIndex);
    if (finalIndex < 0) stateSelector.value?.clearSelection();
    else stateSelector.value?.setSelected(finalIndex);
  },
  clearSelection() {
    stateSelector.value?.clearSelection();
  }
});

</script>

<template>
  <CustomSelector
    :z-index="100000000000"
    :state="stateError ? 'error' : 'default'"
    :supportingText="stateError ?? undefined"
    ref="stateSelector"
    empty-message="Estado"
    :options="StateUtils.states.map((state) => ({title: state}))"
    @change="(_, index) => $emit('change', StateUtils.getStateCodeByIndex(index ?? -1))"
  ></CustomSelector>
</template>

<style scoped></style>