import { cpf } from "cpf-cnpj-validator";
import moment from "moment";

class FormatUtils {
  public static readonly monthsName = [
    'Janeiro',
    'Feveiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];

  public static readonly daysName = [
    'Segunda',
    'Terça',
    'Quarta',
    'Quinta',
    'Sexta',
    'Sábado',
    'Domingo',
  ];

  static validateValue(value: string): string {
    if (parseFloat(value) < 0) return '0';
    return value;
  }
  static formatCPF(cpf: string): string {
    return cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '$1.$2.$3-$4');
  }

  static formatPhone(phone: string): string {
    return phone.replace(/(\d{2})(\d{4,5})(\d{4})/, '($1) $2-$3');
  }

  static money(value: number | null | undefined, addBRL = false): string {
    if (Number.isNaN(value) || value === Infinity || value === -Infinity) value = 0;
    return `${addBRL ? 'R$ ' : ''}${this.beforeComma(value, true)},${this.afterComma(value)}`
  }

  static ean(value: string | null | undefined): string {
    if (!value) return "-";
    return value.padStart(13, '0');
  }

  static beforeComma(value: number | null | undefined, moneyFormat = false): string {
    if (value === null || value === undefined || Number.isNaN(value) || value === Infinity || value === -Infinity) return '-';
    const formatValue = String(Math.floor(value));
    if (formatValue.length > 6 && moneyFormat) return formatValue.replace(/(\d{3})(\d{3})$/g, ".$1.$2");
    if (formatValue.length > 3 && moneyFormat) return formatValue.replace(/(\d{3})$/g, ".$1");
    return formatValue ?? '0';
  }

  static afterComma(value: number | null | undefined): string {
    if (value === null || value === undefined || Number.isNaN(value) || value === Infinity || value === -Infinity) return '-';
    return String(value.toFixed(2)).split('.')[1] ?? '00';
  }

  static ellipsis(value: string | null | undefined, size: number): string {
    if (!value) return "-";
    if (size > value.length) return value;
    return `${value.slice(0, size)}...`;
  }

  static commonNumber(value: number | string | null | undefined, fixed: number | null = null): string {
    if (value === null || value === undefined) return "-";
    if (fixed !== null && typeof value === 'number') value = value.toFixed(fixed);
    if (typeof value === "number" && isNaN(value)) return '-';
    return value.toString().replaceAll('.', ',');
  }

  static date(date: Date | null | undefined | number, showYear = true) {
    if (typeof (date) === 'number') date = new Date(date);
    if (!date) return '-';
    return moment(date).format(`DD/MM${showYear ? '/YYYY' : ''}`);
  }

  static datetime(date: Date | null | undefined | number) {
    if (typeof (date) === 'number') date = new Date(date);
    if (!date) return '-';
    return moment(date).format('DD/MM/YYYY [às] HH:mm');
  }

  static isValidEmail(value: string | null | undefined): boolean {
    if (!value) return false;
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value);
  }

  static isValidPassword(value: string | null | undefined): boolean {
    if (!value) return false;
    if (value.length < 8) return false;
    if (!/[^A-Za-z0-9]/.test(value)) return false;
    if (!/\d/.test(value)) return false;
    return true;
  }

  static isValidCellphone(value: string | null | undefined): boolean {
    if (!value) return false;
    const number = value.replace(/\D/g, '');
    const pattern = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/;
    return pattern.test(number);
  }

  static isValidCpf(cpfToValidate: string | null | undefined): boolean {
    if (!cpfToValidate) return false;
    const cpf = cpfToValidate.replace(/\D/g, '').split('').map(Number);
    if (cpf.length !== 11 || cpf.every(d => d === cpf[0])) return false;
    let calc = 0;
    for (let i = 0, c = 10; c > 1; ++i, c--) {
      calc += c * cpf[i];
    }
    let d = calc % 11 === 0 || calc % 11 === 1 ? 0 : 11 - (calc % 11);
    if (d !== cpf[9]) return false;
    calc = 0;
    for (let i = 1, c = 10; c > 1; ++i, c--) {
      calc += c * cpf[i];
    }
    d = calc % 11 === 0 || calc % 11 === 1 ? 0 : 11 - (calc % 11);
    if (d !== cpf[10]) return false;
    return true;
  }

  static isValidCEP(cep: string | null | undefined): boolean {
    const pattern = RegExp(/^[0-9]{5}-[0-9]{3}$/).test(cep ?? '');
    return pattern;
  }

  static range(start: number, end: number): number[] {
    return [...Array(1 + end - start).keys()].map(v => start + v);
  }

  static humanLocalizedDate(date: Date): string {
    if (!date) return '---';
    const monthName = FormatUtils.monthsName[date.getMonth()];
    const day = date.getDate().toString().padStart(2, '0');
    return `${day} ${monthName.slice(0, 3)}`;
  }

  static onlyDigits(value: string | null | undefined): string | null {
    const build = value?.replace(/\D/g, '').trim();
    return build ?? null;
  }

  static isNumber(value: string): boolean {
    return /^[0-9.,]*$/.test(value);
  }

  static decimalToNumber(value: string | number | null | undefined): number | null {
    if (!value || isNaN(parseFloat(value.toString()))) return null;
    return parseFloat(value.toString()) / 100;
  }

  static numberToDecimal(value: string | number | null | undefined): number | null {
    if (!value || isNaN(parseFloat(value.toString()))) return null;
    const numberValue = Math.trunc(parseFloat(value.toString()) * 100);
    return numberValue;
  }

  static getDaysName(reduced: boolean): string[] {
    if (reduced) return FormatUtils.daysName.map(e => e.slice(0, 3));
    return FormatUtils.daysName;
  }

  static getMonthName(index: number | undefined, reduced = false): string | null {
    if (index === undefined) return null
    if (reduced) return FormatUtils.monthsName[index].slice(0, 3);
    return FormatUtils.monthsName[index];
  }

  static formatNumberText(n: string) {
    let build = '';
    const lastDotComma = Math.max(n.lastIndexOf('.'), n.lastIndexOf(','));
    for (let i = 0; i < n.length; i++) {
      if (!n[i].match(/\d/g) && i != lastDotComma) continue;
      build += n[i];
    }
    if (build.endsWith('.') || build.endsWith(',')) return build.slice(0, -1);
    return build.replaceAll(',', '.');
  }

  static formatMoney(value: string, addBRL = false) {
    if (!value) {
      return '';
    }
    let newValue = value.replace(/\D/g, "");
    if (newValue.length < 3) {
      newValue = newValue.padStart(3, "0");
    }
    let integerPart = newValue.slice(0, -2);
    let decimalPart = newValue.slice(-2);
    integerPart = integerPart.replace(/^0+/, "") || "0";
    integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    const formattedValue = `${integerPart},${decimalPart}`;
    return addBRL ? `R$ ${formattedValue}` : formattedValue;
  }

  static transformString(input: string): number {
    let result = input.replace(/,/g, '.');
    const lastDotIndex = result.lastIndexOf('.');
    if (lastDotIndex !== -1) {
      result = result.substring(0, lastDotIndex).replace(/\./g, '') + result.substring(lastDotIndex);
    }
    const number = parseFloat(result);
    return parseFloat(number.toFixed(2));
  }

  static replaceDecimalSeparator(value: string): string {
    const regex = /[,.]/;
    if (!regex.test(value)) {
      value += ',00';
    }
    const parts = value.split(regex);
    if (parts.length === 2 && parts[1].length === 1) {
      value += "0";
    }
    if (parts.length === 2 && parts[0].length >= 2) {
      return value.replace(".", ",");
    }
    return value;
  }

  static parseFormattedNumber(value: string) {
    if(!value || Number.isNaN(value)) return 0;
    const cleanString = value.replace("R$", "").trim().replace(/\./g, "").replace(",", ".");
    return parseFloat(cleanString);
  }
}

export default FormatUtils;