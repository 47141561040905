import { defineStore } from 'pinia';
import { ValueResult } from '@/models/value-result';
import { DeliveryType } from '@/models/delivery_type_enum';
import { RenewRequest } from '@/models/request-renew/request_renew_model';
import { RequestRenewBFFBackofficeModule } from '@/infra/bff_backoffice/request_renew/request_renew_backoffice_module';
import { GlobalData } from '@/global';
import FormatUtils from '@/utils/format_utils';

export enum RequestRenewPages {
  SELECT_DELIVERY_OPTIONS = 1,
  CONFIRMATION = 2,
}

interface IState {
  basketPlanId: string | undefined;
  observation: string | undefined;
  checkValue: boolean | undefined;
  basketName: string | undefined
  deliveryDateUtc: Date | undefined;
  branchId: string | undefined;
  basketValue: string;
  deliveryType: DeliveryType | undefined;
  currentPage: RequestRenewPages;
  usersCount: number | undefined;
  dateError: string | undefined;
  branchError: string | undefined;
  deliveryError: string | undefined;
  balanceError: string | undefined;
  collaboratorsError: string | undefined;
}

export const useRequestRenew = defineStore('request_renew', {
  state: (): IState => ({
    basketPlanId: undefined,
    observation: undefined,
    checkValue: undefined,
    basketName: undefined,
    basketValue: '',
    deliveryDateUtc: undefined,
    branchId: undefined,
    deliveryType: undefined,
    currentPage: RequestRenewPages.SELECT_DELIVERY_OPTIONS,
    usersCount: undefined,
    dateError:  undefined,
    branchError: undefined,
    balanceError: undefined,
    collaboratorsError: undefined,
    deliveryError: undefined
  }),
  actions: {
    async submit() {  
      const validation = this.validate();
      if (!validation) return validation;
      const renewRequest: RenewRequest[] = [{
        basketPlanId: this.basketPlanId ?? '',
        observation: this.observation,
        deliveryDateUtc: this.deliveryDateUtc ?? new Date(),
        branchId: this.branchId,
        basketValue: FormatUtils.transformString(this.basketValue) ?? 0,
        deliveryType: this.deliveryType
      }].map(params => new RenewRequest(params));

      const res = await RequestRenewBFFBackofficeModule.requestRenewBasketPlan({
        renewRequest
      });
      return res;
    },
    validate(): boolean {
      this.cleanError();
      let canUpdate = true;
      if (parseFloat(this.basketValue) < 1 || Number.isNaN(this.basketValue)) {
        this.balanceError = 'O valor da cesta deve ser um valor numérico maior que 0';
        canUpdate = false;    
      }
      if (!this.deliveryType) {
        this.deliveryError = 'Tipo de entrega não selecionado.';
        canUpdate = false;
      } 
      if (this.deliveryType == DeliveryType.Entrega_na_empresa_ou_Porta_a_porta || this.deliveryType == DeliveryType.Entrega_na_empresa) {
        if(!this.branchId?.length){
          this.branchError = 'Para solicitar a renovação com entrega na empresa é necessario ter um endereço.';
          canUpdate = false;
        }  
      }
      if (!this.checkValue) {
        this.collaboratorsError = 'É necessário confirmar o quadro de colaboradores.',
          canUpdate = false;
      }
      if (!this.deliveryDateUtc) {
          this.dateError = 'Data de entrega deve ser preenchidas corretamente.',
          canUpdate = false;
      }
      return canUpdate;
    },
    cleanError() {
      this.dateError = '',
      this.branchError = '',
      this.balanceError = '',
      this.collaboratorsError = '',
      this.deliveryError = ''
    },
    goToPage(page: RequestRenewPages): ValueResult<void | boolean>  {
      if (page === this.currentPage) return new ValueResult();
      if (this.currentPage === RequestRenewPages.SELECT_DELIVERY_OPTIONS) {
        const validation = this.validate();
        if (!validation) return new ValueResult({value: validation});
      }
      this.currentPage = page;
      return new ValueResult({value:true});
    }
  },
  },
)(GlobalData.Pinia);