import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7c3ef43a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex flex-column gap-3" }
const _hoisted_2 = {
  ref: "contentElem",
  class: "content-paragraph"
}
const _hoisted_3 = { class: "buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["CommonModal"], {
    ref: "alertModal",
    "can-close": $setup.canClose,
    "is-full-screen": $setup.isFullScreen
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass($setup.size === 'small' ? ' alert-modal-small' : ' alert-modal-medium')
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", {
            class: _normalizeClass(`d-flex align-items-center ${$setup.size === 'small' ? 'gap-3' : 'gap-2'}`)
          }, [
            ($setup.icon)
              ? (_openBlock(), _createBlock($setup["FontAwesomeIcon"], {
                  key: 0,
                  icon: $setup.icon,
                  class: "alert-icon",
                  style: _normalizeStyle({color: $setup.getTitleColor()})
                }, null, 8 /* PROPS */, ["icon", "style"]))
              : _createCommentVNode("v-if", true),
            _createElementVNode("h3", {
              ref: "titleElem",
              class: "title",
              style: _normalizeStyle({color: $setup.getTitleColor()})
            }, null, 4 /* STYLE */)
          ], 2 /* CLASS */),
          _createElementVNode("p", _hoisted_2, null, 512 /* NEED_PATCH */),
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ]),
        _createElementVNode("div", _hoisted_3, [
          ($setup.buttons[0] != undefined)
            ? (_openBlock(), _createBlock($setup["CommonButton"], {
                key: 0,
                action: $setup.buttons[0]?.cta ?? 'cta',
                size: $setup.buttons[0]?.size ?? 'medium',
                type: $setup.buttons[0]?.type ?? 'filled',
                onClick: $setup.buttons[0]?.action,
                text: $setup.buttons[0]?.text,
                class: _normalizeClass($setup.size === 'small' ? 'w-100' : '')
              }, null, 8 /* PROPS */, ["action", "size", "type", "onClick", "text", "class"]))
            : _createCommentVNode("v-if", true),
          ($setup.buttons[1] != undefined)
            ? (_openBlock(), _createBlock($setup["CommonButton"], {
                key: 1,
                action: $setup.buttons[1]?.cta ?? 'cta',
                size: $setup.buttons[1]?.size ?? 'medium',
                type: $setup.buttons[1]?.type ?? 'filled',
                onClick: $setup.buttons[1]?.action,
                text: $setup.buttons[1]?.text,
                class: _normalizeClass($setup.size === 'small' ? 'w-100' : '')
              }, null, 8 /* PROPS */, ["action", "size", "type", "onClick", "text", "class"]))
            : _createCommentVNode("v-if", true)
        ])
      ], 2 /* CLASS */)
    ]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["can-close", "is-full-screen"]))
}