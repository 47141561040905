<script setup lang="ts">
import { Icons } from '@/font-awesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import LoaderAnimation from '../LoaderAnimation.vue';
import { ref, computed, onMounted, defineProps, defineEmits, defineExpose, onUnmounted, getCurrentInstance } from 'vue';
import { ValueResult } from '@/models/value-result';
import { AddressModel } from '@/models/address_model';
import { vMaska } from "maska"

const props = defineProps<{
  prefixIcon?: IconDefinition,
  icon?: IconDefinition,
  iconButton?: IconDefinition;
  modelValue: string,
  label: string,
  supportingText?: string,
  state?: 'disabled' | 'error' | 'default',
  type?: string,
  mask?: string;
  tokenMask?: string;
  isStatic?: boolean,
  options?: AddressModel[];
  fetchFunction: () => Promise<ValueResult<AddressModel[] | null>>;
}>();

defineEmits<{
  (e: 'update:model-value', value: string): void,
  (e: 'tap-trailing', evt: Event): void,
  (e: 'leave-focus', value: string): void,
  (e: 'on-paste', evt: ClipboardEvent): void,
  (e: 'change:value', evt: Event, value: boolean): void;
  (e: 'select', value: AddressModel): void
}>()

defineExpose({
  setFocus: (v: boolean) => v ? inputField.value?.focus() : inputField.value?.blur(),
  clearOptions: () => {isFocused.value = false; options = []},
});

const thisInstance = getCurrentInstance();
const labelLeft = 20;
const inputBefore = ref<HTMLElement | null>(null);
const labelElem = ref<HTMLElement | null>(null);
const currentLabelSize = ref(0);
const state = computed(() => props.state ?? 'default');
const isFocused = ref(false);
const isHovered = ref(false);
const isLoading = ref(false);
const floatOn = computed(() => isFocused.value || props.modelValue.length > 0)
const inputField = ref<HTMLInputElement | null>(null);
const inputBackgroundColor = computed(() => `var(--components-input-${state.value}-background-color, rgba(0, 0, 0, 0.00))`);
const loadingStep = ref<InstanceType<typeof LoaderAnimation>>();
const timeoutID = ref<number | undefined>(undefined);
let options: AddressModel[] | null = props.options ?? [];

function getState() {
  if (state.value == 'error') return state.value;
  if (isFocused.value) {
    return "focused";
  }
  if (isHovered.value && state.value != "disabled") {
    return "hovered";
  }
  return `${state.value}`;
}

function timedSearchAddress() {
  clearTimeout(timeoutID.value);
  timeoutID.value = setTimeout(searchAddress, 1000);
}

async function searchAddress() {
  if (inputField.value?.value?.length! !== 9) return;
  loadingStep.value?.startLoading({
    loading: {
      text: 'Pesquisando o endereço digitado...',
      subtext: 'Por favor, aguarde.',
    },
  });
  isLoading.value = true;
  const res = await props.fetchFunction();
  if (res.isSuccess()) {
    options = res?.getValue();
  }
  loadingStep.value?.endLoading({
    isError: res.isError(),
    error: {
      subtext: 'Endereço não encontrado.',
    },
    delayAfterLoaded: res.isError() ? 2000 : 0,
  });
  setTimeout(() => isLoading.value = false, res.isError() ? 2000 : 0);
}

function getOptionTitle(option: AddressModel): string {
  let title = '';
  const street = option.getStreet();
  const streetNumber = option.getStreetNumber();
  const complement = option.getComplement();
  if (street && street !== 'undefined') title += street;
  if (streetNumber && streetNumber !== 'undefined') title += (title ? ', ' : '') + streetNumber;
  if (complement && complement !== 'undefined') title += (title ? ', ' : '') + complement;
  return title;
}

function getOptionSubtitle(option: AddressModel): string {
  let subtitle = '';
  const vicinity = option.getVicinity();
  const city = option.getCity();
  const state = option.getState();
  if (vicinity && vicinity !== 'undefined') subtitle += vicinity;
  if (city && city !== 'undefined') subtitle += (subtitle ? ', ' : '') + city;
  if (state && state !== 'undefined') subtitle += (city && city !== 'undefined' ? '/' : subtitle ? ', ' : '') + state;
  return subtitle;
}

function isStatic(){
  if (props.isStatic === undefined || !props.isStatic){
    return false;
  }
  return true;
}

let resizeObserver: ResizeObserver | null = null;

onMounted(() => {
  resizeObserver = new ResizeObserver(() => {
    currentLabelSize.value = labelElem.value?.offsetWidth ?? 0;
  });
  resizeObserver.observe(labelElem.value!);
});

onUnmounted(() => {
  resizeObserver?.disconnect();
});

</script>

<template>
  <div class="input-father">
    <div class="input">
      <input
        :readonly="isStatic()"
        @mouseover="isHovered = true"
        @mouseleave="isHovered = false"
        @click="isFocused = true"
        :disabled="state == 'disabled'"
        ref="inputField"
        class="common-input"
        v-maska
        :data-maska="'#####-###'"
        @focus="isFocused = true"
        :value="modelValue"
        :style="{
          border: `1px solid var(--components-input-${getState()}-border-color, #CDC8D7)`,
          color: `var(--components-input-${state ?? 'default'}-text-color, #CDC8D7)`,
          fontWeight: `var(--components-input-${state}-font-weight, 800)`,
          borderTop: isFocused || modelValue.length ? `none` : `1px solid var(--components-input-${getState()}-border-color, #CDC8D7)`,
          paddingRight: `calc(22px${props.type == 'password' ? ' + 22px' : ''}${thisInstance?.vnode?.props?.onTapTrailing ? ' + 22px' : ''})`,
        }"
        @input="$emit('update:model-value', ($event.target as HTMLInputElement).value)"
        @focusout="$emit('leave-focus', ($event.target as HTMLInputElement).value)"
        @paste="$emit('on-paste', ($event)); timedSearchAddress()"
        @keydown="
          if ($event.key === 'Enter' && options && options.length > 0) {
            $emit('select', options[0]);
            isFocused = false;
            inputField?.blur();
          } else if ($event.key === 'Escape') {
            isFocused = false;
            inputField?.blur();
          } else {
            options = [];
            timedSearchAddress();
          }
        " />
      <div ref="inputBefore" class="input-before" v-if="(isFocused || modelValue.length) && labelElem" :style="{
        backgroundColor: `var(--components-input-${getState()}-border-color, #CDC8D7)`,
      }"></div>
      <div class="input-after" v-if="(isFocused || modelValue.length) && labelElem" :style="{
        backgroundColor: `var(--components-input-${getState()}-border-color, #CDC8D7)`,
        width: `calc(100% - ${labelLeft}px - (var(--semantic-border-radius-default, 7px) * 2) - ${currentLabelSize}px)`,
      }"></div>
      <div
        ref="labelElem"
        :class="[
          'semantic-typography-body-regular-default',
          'input-field-label',
          {
            'input-field-label-focused': floatOn,
            'semantic-typography-body-bold-small': floatOn
          }
        ]"
        :style="{
          color: `var(--components-input-${getState()}-label-color, #6E6979)`,
          left: labelLeft,
        }"
      >
        <FontAwesomeIcon v-if="icon" :icon="icon" />
        <p>
          {{ label }}
        </p>
      </div>
      <div v-if="modelValue.length" @mouseover="isHovered = true" class="input-field-icons-container">
        <FontAwesomeIcon
          v-if="thisInstance?.vnode?.props?.onTapTrailing"
          :icon="iconButton ?? Icons.imported.faXmark"
          class="input-field-icon"
          :style="{
            color: `var(--components-input-${getState()}-right-icon-color, #6E6979)`,
          }"
          @click="$emit('tap-trailing', $event)"
        />
      </div>
    </div>
      <div
        v-show="isFocused && options!.length > 0"
        class="context-menu"
        v-if="options!.length > 0"
      >
        <div class="options-father">
          <div
            class="option"
            v-for="(option, index) in options"
            @click="$emit('select', option); isFocused = false"
            :key="index"
          >
            <FontAwesomeIcon class="option-icon" :icon="Icons.imported.faLocationDot"/>
            <div class="option-text">
              <p class="option-title">{{ getOptionTitle(option) }}</p>
              <p class="option-subtitle">{{ getOptionSubtitle(option) }}</p>
            </div>
          </div>
        </div>
      </div>
      <div
        v-show="isFocused"
        class="context-menu"
        v-else-if="isLoading"
      >
        <div class="loader">
          <div style="height: 36px;"></div>
          <LoaderAnimation
            ref="loadingStep"
          />
          <div style="height: 36px;"></div>
        </div>
      </div>
    <p class="supporting-text" :style="`color: var(--components-input-${getState()}-supporting-text-color)`"
      v-if="supportingText">
      {{ supportingText }}
    </p>
  </div>
</template>

<style lang="scss" scoped>

.input-father {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.icon {
  color: var(--components-input-default-right-icon-color, #AAA4B6);
}

.common-input {
  border-radius: var(--semantic-border-radius-default, 7px);
  padding: var(--semantic-spacing-inset-200, 16px);
  font-size: 16px;
  padding-left: 24px;
  width: 100%;
  background-color: v-bind('inputBackgroundColor');
  border-top: none;
  position: relative;
}

.input {
  position: relative;
}

.input-after,
.input-before {
  position: absolute;
  top: 0;
  height: .1px;
}

.input-before {
  left: calc(var(--semantic-border-radius-default, 7px) / 2);
  width: 10px;
}

.input-after {
  right: calc(var(--semantic-border-radius-default, 7px) / 2);
}

.input-field-icons-container {
  position: absolute;
  display: flex;
  gap: 4px;
  align-items: center;
  bottom: 18px;
  right: 20px;
}

.input-field-icon {
  cursor: pointer;
  width: 20px;
}

.input-field-label {
  position: absolute;
  left: 21px;
  top: 50%;
  line-height: 16px;
  transition: all 0.1s;
  pointer-events: none;
  display: flex;
  padding: 0px var(--semantic-spacing-stack-025, 2px);
  gap: var(--semantic-spacing-inline-100, 8px);
  align-items: center;
  transform: translate(0%, -50%);
}

.input-field-label-focused {
  position: absolute;
  top: 0%;
  font-size: 12px;
  gap: var(--semantic-spacing-inline-100, 4px);
}

input[type=password]::-ms-reveal,
input[type=password]::-ms-clear {
  display: none;
}

.context-menu {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  gap: 10px;
  border-radius: 7px;
  background: #FFF;
  color: var(--components-input-default-text-color, #CDC8D7);
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.10);
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  top: 53px;
  max-height: 200px;
  max-width: 100vw;
  padding-top: 10px;
  cursor: initial;
  z-index: 100000;
}

.options-father {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  gap: 10px;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.option {
  display: flex;
  padding: 24px 32px;
  align-items: center;
  align-self: stretch;
  border-radius: 7px;
  border: 1px solid #F2F1F3;
  background: #FFF;
  flex-wrap: wrap;
  gap: var(--semantic-spacing-inline-200, 16px);
}

.option-icon {
  height: 20px;
  width: 20px;
  color: var(--components-input-default-right-icon-color, #6E6979);
}

.option-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2px;
}

.option-title {
  color: var(--semantic-color-fg-default, #5D5867);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.option-subtitle {
  color: var(--semantic-color-fg-muted, #6E6979);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.option:hover {
  background: #F2F1F3;
}

.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.supporting-text {
  font-family: 'Montserrat';
  font-size: 12px;
  font-weight: 300;
}

</style>
