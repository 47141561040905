export class StateUtils {

  public static readonly states = [
    "AC", "AL", "AP", "AM", "BA", "CE", "DF", "ES", "GO", "MA",
    "MT", "MS", "MG", "PA", "PB", "PR", "PE", "PI", "RJ", "RN",
    "RS", "RO", "RR", "SC", "SP", "SE", "TO"
  ];

  public static readonly statesName = [
    "Acre", "Alagoas", "Amapá", "Amazonas", "Bahia", "Ceará", "Distrito Federal", "Espírito Santo", "Goiás", "Maranhão",
    "Mato Grosso", "Mato Grosso do Sul", "Minas Gerais", "Pará", "Paraíba", "Paraná", "Pernambuco", "Piauí", "Rio de Janeiro", "Rio Grande do Norte",
    "Rio Grande do Sul", "Rondônia", "Roraima", "Santa Catarina", "São Paulo", "Sergipe", "Tocantins",
  ];

  static getStateCodeByIndex(index: number): string | null {
    if (index < 0 || index >= this.states.length) return null;
    return this.states[index];
  }

  static getStateFullNameByIndex(index: number): string | null {
    if (index < 0 || index >= this.statesName.length) return null;
    return this.statesName[index];
  }

  static findStateIndexByFullName(state: string): number {
    return this.statesName.findIndex((e) => {
      return this.formatCompare(state).includes(this.formatCompare(e));
    });
  }

  static formatCompare(text: string): string {
    let formatted = text.toUpperCase();
    formatted = formatted.replace(/[ÁÃ]/g, 'A');
    formatted = formatted.replace(/[ÊÉ]/g, 'E');
    formatted = formatted.replace(/[ÎÍ]/g, 'I');
    formatted = formatted.replace(/[ÔÓ]/g, 'O');
    formatted = formatted.replace(/[ÛÚ]/g, 'U');
    return formatted;
  }

  static findStateIndexByCode(state: string): number {
    return this.states.indexOf(state);
  }

  static getStateCodeByFullName(state: string): string | null {
    return this.getStateCodeByIndex(this.findStateIndexByFullName(state));
  }

}