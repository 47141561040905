import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Teleport as _Teleport, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c747386e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "buttons-controller" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Teleport, {
    to: "body",
    disabled: !$props.teleport
  }, [
    _createElementVNode("div", {
      class: "blackout",
      style: _normalizeStyle({
      zIndex: $setup.zIndexRef
    }),
      ref: "blackout"
    }, [
      _createElementVNode("div", {
        ref: "commonModal",
        class: "common-modal",
        style: _normalizeStyle($props.isFullScreen ? { width: '100%', height: '100%', borderRadius: '0', overflowY:'auto', padding: '0' } : {})
      }, [
        _createElementVNode("div", {
          class: _normalizeClass($props.isFullScreen ? 'modal-fullscreen-content' : ''),
          style: _normalizeStyle( $props.isFullScreen && $props.isTop ? { alignSelf:'start', height: '100%'} : {})
        }, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ], 6 /* CLASS, STYLE */),
        _createElementVNode("div", _hoisted_1, [
          ($props.canMinimize)
            ? (_openBlock(), _createBlock($setup["FontAwesomeIcon"], {
                key: 0,
                icon: $setup.Icons.imported.faWindowMinimize,
                class: "control-btn",
                onClick: _cache[0] || (_cache[0] = () => {
              $setup.minimizeButton();
              _ctx.$emit('on-minimize-by-button');
            })
              }, null, 8 /* PROPS */, ["icon"]))
            : _createCommentVNode("v-if", true),
          ($props.canClose)
            ? (_openBlock(), _createBlock($setup["FontAwesomeIcon"], {
                key: 1,
                icon: $setup.Icons.imported.faXmark,
                class: "control-btn",
                onClick: _cache[1] || (_cache[1] = () => {
              $setup.closeButton(undefined);
              _ctx.$emit('on-close-by-button');
            })
              }, null, 8 /* PROPS */, ["icon"]))
            : _createCommentVNode("v-if", true)
        ])
      ], 4 /* STYLE */)
    ], 4 /* STYLE */)
  ], 8 /* PROPS */, ["disabled"]))
}