import { DeliveryAreasBFFBackofficeModule } from '@/infra/bff_backoffice/delivery_areas/delivery_areas_backoffice_module';
import Constants from '@/infra/constants/constants';
import LoggedAdminStorage from '@/infra/local_storage/logged_admin_storage';
import { SelectedDeliveryArea } from '@/infra/local_storage/selected_delivery_area';
import { DeliveryAreaModel } from '@/models/delivery_area_model';
import { ValueResult } from '@/models/value-result';
import { defineStore } from 'pinia';

interface IState {
  deliveryAreas: DeliveryAreaModel[]; 
  selectedDeliveryArea: DeliveryAreaModel | null;
  currentPage: number;
  pageCount: number;
  searchTerm?: string;
}

export const useDeliveryAreasStore = defineStore('delivery_areas_store', {
  state: (): IState => ({
    deliveryAreas: [],
    selectedDeliveryArea: SelectedDeliveryArea.getSelectedDeliveryArea(),
    currentPage: 1,
    pageCount: 1,
    searchTerm: ''
  }),
  actions: {
    reset() {
      this.deliveryAreas = [];
      this.selectedDeliveryArea = null;
    },
    async fetchDeliveryAreas(): Promise<ValueResult<DeliveryAreaModel[] | null>> {
      const res = await DeliveryAreasBFFBackofficeModule.getDeliveryAreasByCompanyId({
        companyId: Constants.childCompanyId[0],
        page: this.currentPage,
        pageSize: 8,
        searchTerm: this.searchTerm ?? undefined,
      });
      if (res.getError()) return new ValueResult({ error: res.getError() });
      this.deliveryAreas = res.getValue()!.items! as DeliveryAreaModel[];
      this.pageCount = res.getValue()!.pageCount!;
      return new ValueResult();
    },
    async fetchDeliveryAreasByCompanyId(params: { id: string }): Promise<ValueResult<DeliveryAreaModel[] | null>> {
      const res = await DeliveryAreasBFFBackofficeModule.getDeliveryAreasByCompanyId({
        companyId: params.id,
        page: this.currentPage,
        pageSize: 8,
        searchTerm: this.searchTerm ?? undefined,
      });
      if (res.getError()) return new ValueResult({ error: res.getError() });
      this.deliveryAreas = res.getValue()!.items! as DeliveryAreaModel[];
      this.pageCount = res.getValue()!.pageCount!;
      return new ValueResult();
    },
    async getDeliveryAreaById(params: { id: string }): Promise<ValueResult<DeliveryAreaModel[] | null>> {
      const res = await DeliveryAreasBFFBackofficeModule.getDeliveryAreaById({
        id: params.id,
      });
      if (res.getError()) return new ValueResult({ error: res.getError() });
      this.deliveryAreas = [res.getValue()!] as DeliveryAreaModel[];
      return new ValueResult();
    },
    async selectDeliveryArea(params: { id: string }): Promise<ValueResult<DeliveryAreaModel | null>> {
      const res = await DeliveryAreasBFFBackofficeModule.getDeliveryAreaById({
        id: params.id,
      });
      if (res.isError()) return new ValueResult({ error: res.getError() });
      this.selectedDeliveryArea = res.getValue()!;
      SelectedDeliveryArea.setSelectedDeliveryArea(this.selectedDeliveryArea as DeliveryAreaModel);
      return new ValueResult<DeliveryAreaModel>({ value: res.getValue()! });
    },
    async clearSelectedDeliveryArea() {
      this.selectedDeliveryArea = null;
      this.deliveryAreas = [];
      SelectedDeliveryArea.clearSelectedDeliveryArea();
      const user = LoggedAdminStorage.getUser();
      if (user?.getIsSuperAdmin() === undefined) await this.selectDeliveryArea({ id: user?.getDeliveryAreaId()! });
    },
    getDeliveryAreaId(): string | null {
      return this.selectedDeliveryArea?.getId() ?? LoggedAdminStorage.getUser()?.getDeliveryAreaId() ?? null;
    },
    async setPage(params: {
      page: number,
    }) {
      this.currentPage = params.page;
      await this.fetchDeliveryAreas();
    },
    async setSearchTerm(params: {
      searchTerm: string,
    }) {
      this.searchTerm = params.searchTerm;
      this.currentPage = 1;
      this.fetchDeliveryAreas();
    },
    
    getDeliveryAreas() {
      return this.deliveryAreas;
    }
    
  },
  
});