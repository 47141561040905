import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d158ba9c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "minimized-modal" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.minimizedModalStore.modals, (card) => {
      return (_openBlock(), _createBlock($setup["MinimizedModalCard"], {
        key: card.id,
        minimized: card
      }, null, 8 /* PROPS */, ["minimized"]))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}