<script setup lang="ts">
import { defineProps } from 'vue';
import FormatUtils from '@/utils/format_utils';

defineProps<{
  title: string,
  lastUpdate: Date | null | undefined,
  supportingText?: string;
}>();
</script>
<template>
  <div class="staff-container ">
    <div class="text-container">
      <p class="first-text">{{ title }}</p>
      <p v-if="lastUpdate" class="second-text">
        <span>Última Atualização</span>
        {{ FormatUtils.datetime(lastUpdate) }}
      </p>
      <p v-else class="second-text">
        <span>O plano não possui colaboradores</span>
      </p>
    </div>
    <i class="bi bi-arrow-right"></i>
  </div>
</template>
<style scoped>
.staff-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 24px 32px;
  align-items: center;
  cursor: pointer;
  border-radius: 7px;
  border: 1px solid #F2F1F3;
  background: #FFF;
  justify-content: space-between;
}

.text-container {
  flex-direction: column;
  align-items: flex-start;
  padding-right: 32px;
}

.second-text {
  color: var(--semantic-color-gray-subtle, var(--semantic-color-gray-subtle, #6E6979));
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%;
}

.first-text {
  color: var(--option-color-gray-800, #434049);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

@media screen and (max-width: 500px) {

  .text-container {
    padding-right: 12px;
  }

}
</style>