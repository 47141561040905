<script setup lang="ts">
import { Icons } from '@/font-awesome';
import CustomSelector from './CustomSelector.vue';
import { BranchAddresses } from '@/models/branch_addresses_model';
import { ref } from 'vue';
import CreateBranchModal from '../modals/CreateBranchModal.vue';

const createBranchModal = ref<InstanceType<typeof CreateBranchModal>>();
const customSelector = ref<InstanceType<typeof CustomSelector>>();

defineProps<{
  branches: BranchAddresses;
  zIndex?: number;
  supportingText?: string;
}>();

defineEmits<{
  (e: 'change', evt: Event | null, index: number | null): void;
  (e: 'set', evt: Event | null, branchId: string | null): void;
  (e: 'update:model-value', evt: Event | null, index: number | null): void;
  (e: 'create', evt: Event | null, branches: BranchAddresses): void;
}>();

defineExpose({
  setSelected: (value: number) => {
    customSelector.value?.setSelected(value);
  },
  clearSelection: () => {
    customSelector.value?.clearSelection();
  },
});

</script>

<template>
  <CreateBranchModal ref="createBranchModal"></CreateBranchModal>
  <CustomSelector
    ref="customSelector"
    :supportingText="supportingText"
    :state="supportingText? 'error' : 'default'"
    :icon="Icons.imported.faLocationDot"
    empty-message="Endereço de entrega da sede"
    :options="branches.getBranches().map((branch, index) => ({
      title: branches.getIndex(index)?.branch?.getName() ?? 'Title',
      subtitle: branches.getIndex(index)?.address?.getFullAddress(),
    }))"
    :button="{
      icon: Icons.imported.faPlus,
      text: 'Criar nova sede',
      click: (_) => {
        createBranchModal?.open({
          zIndex,
          onCreate: (newBranch: BranchAddresses | null) => {
            if (newBranch) {
              $emit('create', null, newBranch);
            }
            createBranchModal?.close();
          }
        });
      },
    }"
    no-options-message="Você ainda não possui nenhuma sede cadastrada"
    @change="(evt, index) =>{$emit('change', evt, index) }"
  ></CustomSelector>
</template>

<style scoped>
</style>