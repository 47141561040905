import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-74a01309"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "user-count-container" }
const _hoisted_2 = { class: "count-user" }
const _hoisted_3 = { class: "count-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode($setup["FontAwesomeIcon"], {
        class: "information-icon",
        "fixed-width": true,
        style: {
        width: '8px',
        height: '10px',
      },
        icon: $setup.Icons.imported.faUser
      }, null, 8 /* PROPS */, ["icon"]),
      _createElementVNode("p", _hoisted_3, _toDisplayString($props.usersCount), 1 /* TEXT */)
    ])
  ]))
}