import { defineStore } from "pinia";
import { BranchAddressesBFFBackofficeModule } from "@/infra/bff_backoffice/branch_addresses/branch_addresses_backoffice_module";
import { useDeliveryAreasStore } from "@/stores/delivery_areas/delivery_areas_store";
import { AddressModel } from "@/models/address_model";
import FormatUtils from "@/utils/format_utils";
import { LocationsBFFBackofficeModule } from "@/infra/bff_backoffice/locations/locations_bff_backoffice_module";
import { StateUtils } from "@/utils/state_utils";

interface IState {
  branchName: string;
  branchImgUrl: string;
  hasNumber: boolean;
  address: AddressModel;
  errors: {
    branchNameError: string | null;
    cepError: string | null;
    stateError: string | null;
    cityError: string | null;
    vicinityError: string | null;
    streetError: string | null;
    streetNumberError: string | null;
  }
}

const deliveryAreasStore = useDeliveryAreasStore();

export const useCreateBranch = defineStore("createBranch", {
  state: (): IState => ({
    branchName: "",
    branchImgUrl: "",
    hasNumber: true,
    address: new AddressModel({
      street: "",
      streetNumber: "",
      complement: "",
      vicinity: "",
      city: "",
      country: "BRASIL",
      state: "",
      cep: "",
      lat: 0,
      long: 0,
    }),
    errors: {
      branchNameError: null,
      cepError: null,
      stateError: null,
      cityError: null,
      vicinityError: null,
      streetError: null,
      streetNumberError: null,
    }
  }),
  actions: {
    async submit() {
      const validation = this.validate();
      if (!validation) return validation;
      return await BranchAddressesBFFBackofficeModule.createBranchAddresses({
        deliveryAreaId: deliveryAreasStore.selectedDeliveryArea!.getId(),
        branches: [{
          branch: {
            name: this.branchName
          },
          address: this.address as AddressModel
        }]
      });
    },

    async fetchAddressByCEP() {
      const res = await LocationsBFFBackofficeModule.getAddressBy({ searchTerm: this.address.getCep() });
      return res;
    },

    resetErrors() {
      this.errors.branchNameError = "";
      this.errors.cepError = "";
      this.errors.stateError = "";
      this.errors.cityError = "";
      this.errors.vicinityError = "";
      this.errors.streetError = "";
      this.errors.streetNumberError = "";
    },

    setAddress(address: AddressModel) {
      this.address.setStreet(address.getStreet() ?? '');
      this.address.setVicinity(address.getVicinity() ?? '');
      this.address.setCity(address.getCity() ?? '');
      this.address.setState((address.getState().length > 2 ? StateUtils.getStateCodeByFullName(address.getState()) : address.getState()) ?? '');
      this.address.setCep(address.getCep() ?? '');
      this.address.setStreetNumber('');
      this.address.setComplement('');
      this.resetErrors();
    },

    validate(): boolean {
      if (this.branchName.length < 6) {
        this.errors.branchNameError = "O Nome da filial deve conter pelo menos 6 caracteres";
      }
      if (this.address.getCep().length < 8 || !FormatUtils.isValidCEP(this.address.getCep())) {
        this.errors.cepError = "O CEP da área deve conter pelo menos 8 caracteres e ser no formato XXXXX-XXX.";
      }
      if (this.address.getCity().length < 3) {
        this.errors.cityError = "A Cidade da área deve conter pelo menos 3 caracteres";
      }
      if (this.address.getState().length < 2) {
        this.errors.stateError = "A UF da área deve conter pelo menos 2 caracteres";
      }
      if (this.address.getStreet().length < 3) {
        this.errors.streetError = "O Nome da Rua da área deve conter pelo menos 3 caracteres";
      }
      if (this.address.getVicinity().length < 3) {
        this.errors.vicinityError = "O Bairro da área deve conter pelo menos 3 caracteres";
      }
      if (!this.address.getStreetNumber()) {
        if (this.hasNumber) {
          this.errors.streetNumberError = "O Número da Rua deve ser preenchido ou o campo 'Endereço sem número' deve ser marcado.";
        }
        this.address.setStreetNumber("S/N");
      }
      return Object.values(this.errors).every((e) => !e);
    },

    canCreate(): boolean {
      if (!this.branchName) return false;
      if (!this.address.getCep()) return false;
      if (!this.address.getState()) return false;
      if (!this.address.getCity()) return false;
      if (!this.address.getVicinity()) return false;
      if (!this.address.getStreet()) return false;
      if (this.hasNumber && !this.address.getStreetNumber()) return false;
      return true;
    },

    getName() {
      return this.branchName;
    },

    getImgUrl() {
      return this.branchImgUrl;
    }
  },
});
