import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["CustomSelector"], {
    "z-index": 100000000000,
    state: $props.cityError ? 'error' : $setup.stateCode ? 'default' : 'disabled',
    supportingText: $props.cityError ?? undefined,
    ref: "citySelector",
    "empty-message": "Cidade",
    options: $setup.stateCode ? $setup.CityUtils.cities[$setup.stateCode].map(city => ({title: city})) : [{title: ''}],
    onChange: _cache[0] || (_cache[0] = (_, index) => _ctx.$emit('change', $setup.CityUtils.getCityNameByIndex($setup.stateCode, index ?? -1)))
  }, null, 8 /* PROPS */, ["state", "supportingText", "options"]))
}