<script setup lang="ts">
import CurrencyValue from '@/components/CurrencyValue.vue';
import TwoFactorAuthModal from '@/components/modals/TwoFactorAuthModal.vue';
import StatusPlan from '@/components/StatusPlanComponent.vue';
import Tooltip from '@/components/Tooltip.vue';
import { LoadingModalInjection, TwoFactorAuthModalInjection } from '@/infra/constants/injections';
import LoggedAdminStorage from '@/infra/local_storage/logged_admin_storage';
import { AdminUser } from '@/models/admins/admin_model';
import { Permission } from '@/models/admins/permission_model';
import { Resource } from '@/models/admins/resources_enum';
import { BasketPlan } from '@/models/basket_plans/basket_plan_model';
import { BranchAddresses } from '@/models/branch_addresses_model';
import router, { routes } from '@/router';
import { useCreateBasketPlanStore } from '@/stores/basket_plans/create_basket_plan_store';
import FormatUtils from '@/utils/format_utils';
import { defineEmits, defineProps, getCurrentInstance, inject, ref, Ref } from 'vue';
import BasketPlanStatus from '../BasketPlanStatus.vue';
import CardBlob from '../CardBlob.vue';
import ContextMenu from '../ContextMenu.vue';
import LoadingModal from '../modals/LoadingModal.vue';
import RenewBasketPlanModal from '../modals/RenewBasketPlanModal.vue';
import UserCountComponent from '../UserCountComponent.vue';

const thisInstance = getCurrentInstance();

const props = defineProps<{
  basketPlan: BasketPlan;
  hideControls?: boolean;

}>();

const emit = defineEmits<{
  (e: 'change-page'): void;
  (e: 'update'): void;
  (e: 'request-renew'): void;
}>();

const renewBasketPlanModal = ref<InstanceType<typeof RenewBasketPlanModal>>();
const twoFactorAuthModal = inject<Ref<InstanceType<typeof TwoFactorAuthModal>>>(TwoFactorAuthModalInjection);
const loadingModal = inject<Ref<InstanceType<typeof LoadingModal>>>(LoadingModalInjection);
const createBasketPlanStore = useCreateBasketPlanStore;

async function renew() {
  try {
    loadingModal?.value?.open({ zIndex: 1000 });
    await createBasketPlanStore.fetchBranches({
      deliveryAreaId: props.basketPlan.getDeliveryAreaId(),
    });
    const branchAddresses = createBasketPlanStore.branchAddresses;
    const res = await renewBasketPlanModal.value?.open({
      basketPlanId: props.basketPlan.getId(),
      zIndex: 1000,
      branchAddresses: branchAddresses as BranchAddresses,
    });
    if (res?.isSuccess()) {
      createBasketPlanStore.$reset();
      emit('update');
    }
  } finally {
    loadingModal?.value?.close();
  }
}

async function set2FAUser(executeFunction: () => void) {
  if (!LoggedAdminStorage.getIsTwoFactorAuthorized()) {
    await twoFactorAuthModal?.value?.open({
      user: LoggedAdminStorage.getUser() as AdminUser,
      zIndex: 1000,
    });
  }
  if (LoggedAdminStorage.getIsTwoFactorAuthorized()) executeFunction();
}

const textEllipsisSize = 14;

</script>

<template>
  <RenewBasketPlanModal ref="renewBasketPlanModal"/>
  <div class="card-father" @click="() => $emit('change-page')" :class="{
    'card-father-hover': thisInstance?.vnode?.props?.changePage,
  }">
    <div class="card-content">
      <img src="@/assets/icons/basket_icon.svg" width="27" />
      <div style="height: 14px;"></div>
      <CurrencyValue :value="basketPlan.getValue()" :fontReal="28" :fontSize="16" color="pink"  />
      <Tooltip :text="basketPlan.getName()" v-if="basketPlan.getName().length >= textEllipsisSize">
        <h3 class="name" alt="Blob pattern">{{ FormatUtils.ellipsis(basketPlan.getName(), textEllipsisSize) }}</h3>
      </Tooltip>
      <h3 class="name" v-else alt="Blob pattern">{{ basketPlan.getName() }}</h3>
      <div style="height: var(--semantic-size-semantic-spacing-stack-200, 16px);"></div>
      <UserCountComponent :usersCount="basketPlan.getUsersCount() ?? 0"></UserCountComponent>
      <div style="height: var(--semantic-size-semantic-spacing-inline-100, 8px);"></div>
      <StatusPlan :basketPlan="basketPlan" />
    </div>
    <div class="card-data-footer">
      <BasketPlanStatus v-if="basketPlan.isActive()" :basketPlan="basketPlan" />
    </div>
    <ContextMenu
      v-if="!hideControls"
      class="context-menu"
      :buttons="[
        {
          text: 'Renovar',
          action: () => set2FAUser(renew),
          visible: LoggedAdminStorage.getUser()?.getIsSuperAdmin() && !basketPlan.getWasPaid(),
        },
        {
          text: 'Pedir renovação',
          visible: !LoggedAdminStorage.getUser()?.getIsSuperAdmin() && LoggedAdminStorage.getUser()?.hasPermissions([Permission.fromResource(Resource.BASKET_PLANS, 'write')]) && !basketPlan.getWasPaid() && Boolean(basketPlan.getUsersCount()),
          action: () => $emit('request-renew'),
        },
        {
          text: 'Atualizar sortimento',
          action: () => router.push({
            name: routes.updateCartProducts.name, params: {
              basketPlanId: basketPlan.getId(),
            }
          }),
          visible: LoggedAdminStorage.getUser()?.getIsSuperAdmin(),
        }
      ]"
    ></ContextMenu>
    <CardBlob class="blob" fill="#FF508C"/>
  </div>
</template>

<style scoped>

.card-father {
  width: 100%;
  min-height: 450px;
  height: fit-content;
  min-width: 261px;
  padding: var(--semantic-spacing-inset-400, 32px);
  border-radius: var(--semantic-border-radius-default, 7px);
  border: 1px #F2F1F3;
  background: #FFF;
  gap: var(--semantic-spacing-stack-600, 48px);
  box-shadow: 0px 0px 16px 0px rgba(81, 77, 85, 0.07);
  font-weight: 500;
  font-size: 24px;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  transition: all 0.2s ease-in-out;
  position: relative;
}

.card-father:hover:not(:has(.context-menu:hover)) {
  box-shadow: 0px 0px 24px 0px rgba(81, 77, 85, 0.20);
  cursor: pointer;
}

.card-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  z-index: auto;
}

.card-father-hover {
  background-color: #F6F6F6;
  border: 1px solid #F6F6F6;
  cursor: pointer;
}

.card-father .name {
  color: #434049;
  font-family: 'Montserrat';
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.card-data-footer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 1;
}

.context-menu {
  position: absolute;
  right: 12px;
  top: 12px;
}

.blob {
  position: absolute;
  left: -1px;
  top: -1px;
  border-radius: 6px;
}

</style>