import { normalizeStyle as _normalizeStyle, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-64478e02"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "status-plan-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "status-plan",
      style: _normalizeStyle({color:$props.basketPlan!.getTextColor(),background:$props.basketPlan!.getBackGroundColor()})
    }, [
      _createVNode($setup["FontAwesomeIcon"], {
        "fixed-width": true,
        style: _normalizeStyle({
        width: '12px',
        height: '12px',
        color:$props.basketPlan!.getTextColor()
      }),
        icon: $props.basketPlan!.getStatusIcon()
      }, null, 8 /* PROPS */, ["style", "icon"]),
      _createElementVNode("p", null, _toDisplayString($props.basketPlan!.getStatusPlan() + ' ' + $props.basketPlan?.getMonthString()), 1 /* TEXT */)
    ], 4 /* STYLE */)
  ]))
}