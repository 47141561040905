import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6a70fee0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "input-father" }
const _hoisted_2 = { class: "input" }
const _hoisted_3 = ["disabled", "type", "value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("textarea", {
        onMouseover: _cache[0] || (_cache[0] = ($event: any) => ($setup.isHovered = true)),
        onMouseleave: _cache[1] || (_cache[1] = ($event: any) => ($setup.isHovered = false)),
        disabled: $setup.state == 'disabled',
        ref: "textField",
        class: "text-input",
        type: $props.type ?? 'text',
        onFocus: _cache[2] || (_cache[2] = ($event: any) => ($setup.isFocused = true)),
        onBlur: _cache[3] || (_cache[3] = ($event: any) => ($setup.isFocused = false)),
        value: $props.modelValue,
        style: _normalizeStyle({
          border: `1px solid var(--components-input-${$setup.getState()}-border-color, #CDC8D7)`,
          borderTop: $setup.isFocused || $props.modelValue.length ? `none` : `1px solid var(--components-input-${$setup.getState()}-border-color, #CDC8D7)`,
          resize: 'none',
          minHeight: '100px'
        }),
        onInput: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('update:model-value', ($event.target as HTMLTextAreaElement).value))),
        onFocusout: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('leave-focus', ($event.target as HTMLTextAreaElement).value))),
        onPaste: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('on-paste', ($event)))),
        onKeydown: _cache[7] || (_cache[7] = ($event: any) => (_ctx.$emit('key-down', ($event))))
      }, null, 44 /* STYLE, PROPS, NEED_HYDRATION */, _hoisted_3),
      (($setup.isFocused || $props.modelValue.length) && $setup.labelElem)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            ref: "inputBefore",
            class: "input-before",
            style: _normalizeStyle({
        backgroundColor: `var(--components-input-${$setup.getState()}-border-color, #CDC8D7)`,
      })
          }, null, 4 /* STYLE */))
        : _createCommentVNode("v-if", true),
      (($setup.isFocused || $props.modelValue.length) && $setup.labelElem)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: "input-after",
            style: _normalizeStyle({
        backgroundColor: `var(--components-input-${$setup.getState()}-border-color, #CDC8D7)`,
        width: `calc(100% - ${$setup.labelLeft}px - (var(--semantic-border-radius-default, 7px) * 2) - ${$setup.currentLabelSize}px)`,
      })
          }, null, 4 /* STYLE */))
        : _createCommentVNode("v-if", true),
      _createElementVNode("div", {
        ref: "labelElem",
        class: _normalizeClass([
        'semantic-typography-body-regular-default',
        'input-field-label',
        {
          'input-field-label-focused': $setup.floatOn,
          'semantic-typography-body-bold-small': $setup.floatOn
        }
      ]),
        style: _normalizeStyle({
        color: `var(--components-input-${$setup.getState()}-label-color, #6E6979)`,
        left: $setup.labelLeft,
      })
      }, [
        _createElementVNode("p", null, _toDisplayString($props.label), 1 /* TEXT */)
      ], 6 /* CLASS, STYLE */)
    ]),
    ($props.supportingText)
      ? (_openBlock(), _createElementBlock("p", {
          key: 0,
          class: "supporting-text",
          style: _normalizeStyle(`color: var(--components-input-${$setup.getState()}-supporting-text-color)`)
        }, _toDisplayString($props.supportingText), 5 /* TEXT, STYLE */))
      : _createCommentVNode("v-if", true)
  ]))
}