import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["CustomSelector"], {
    "z-index": 100000000000,
    state: $props.stateError ? 'error' : 'default',
    supportingText: $props.stateError ?? undefined,
    ref: "stateSelector",
    "empty-message": "Estado",
    options: $setup.StateUtils.states.map((state) => ({title: state})),
    onChange: _cache[0] || (_cache[0] = (_, index) => _ctx.$emit('change', $setup.StateUtils.getStateCodeByIndex(index ?? -1)))
  }, null, 8 /* PROPS */, ["state", "supportingText", "options"]))
}