import { AddressModel } from "@/models/address_model";
import { ValueResult } from "@/models/value-result";
import { JsonSerializer } from "typescript-json-serializer";
import BffFoodPassBackofficeApiRequester from "../bff_backoffice_api_requester";


export class LocationsBFFBackofficeModule {

  static readonly controller = "locations";
  static readonly serializer = new JsonSerializer();

  static async getAddressBy(params: {
    searchTerm: string;
  }): Promise<ValueResult<AddressModel[] | null>> {
    try {
      const res = await BffFoodPassBackofficeApiRequester.getAxios().get(`/${this.controller}/${params.searchTerm}`);
      const value = this.serializer.deserializeObjectArray(res.data, AddressModel) as AddressModel[];
      return new ValueResult({ value });
    } catch(e) {
      return ValueResult.fromError(e);
    }
  }

}