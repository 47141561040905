<script setup lang="ts">
import { ref, defineExpose, inject, Ref, onMounted, onUnmounted } from 'vue';
import CommonModal from './CommonModal.vue';
import LoadingModal from './LoadingModal.vue';
import AlertModal from './AlertModal.vue';
import TextAreaInput from '../TextAreaInput.vue';
import Datepicker from '@vuepic/vue-datepicker';
import CommonToggle from '@/components/CommonToggle.vue';
import EditValueComponent from '@/components/EditValueComponent.vue';
import CommonButton from '../CommonButton.vue';
import StaffComponent from '../StaffComponent.vue';
import { AlertModalInjection, LoadingModalInjection } from '@/infra/constants/injections';
import CurrencyValue from '@/components/CurrencyValue.vue';
import { useRouter } from 'vue-router';
import { routes } from '@/router';
import { useCreateBasketPlanStore } from '@/stores/basket_plans/create_basket_plan_store';
import { BranchAddresses } from '@/models/branch_addresses_model';
import FormatUtils from '@/utils/format_utils';
import { useRequestRenew, RequestRenewPages } from '@/stores/requestRenew/request_renew_basket_plan_store';
import DeliverySelector from '../custom_selector/DeliverySelector.vue';
import { BasketPlan } from '@/models/basket_plans/basket_plan_model';
import BasketPlanCard from '../single_delivery_area_view/BasketPlanCard.vue';
import UsersCounter from '../UsersCounter.vue';
import PaymentMethodCard from '../payment_method/PaymentMethodCard.vue';
import { PaymentMethodTypes } from '@/models/payment_method/payment_method_types_enum';
import { MinimizedType } from '@/stores/minimized_modal_store/minimized_modal_store';
import { DeliveryType } from '@/models/delivery_type_enum';

const loadingModal = inject<Ref<InstanceType<typeof LoadingModal>>>(LoadingModalInjection);
const createBasketPlanStore = useCreateBasketPlanStore;
const alertModal = inject<Ref<InstanceType<typeof AlertModal>>>(AlertModalInjection);
const requestRenewBasketPlanModal = ref<InstanceType<typeof CommonModal>>();
const deliverySelector = ref<InstanceType<typeof DeliverySelector>>();
const deliveryType = ref<DeliveryType | null>(null);
const pickupBranchId = ref<string | null>(null);
const editValueComponent = ref<InstanceType<typeof EditValueComponent>>();
const deliveryAreaId = ref<string>();
const basketValue = ref<string>();
const router = useRouter();
const zIndex = ref(0);
const lastUpdateuser = ref<Date | null>();
const requestRenew = useRequestRenew;
const basketPlan = ref<BasketPlan | null>(null);
const isMouseOver = ref(false);

defineExpose({
  open: async (params: {
    basketPlan: BasketPlan | null,
    zIndex?: number
  }) => {
    clearModal();
    try {
      const promise = requestRenewBasketPlanModal.value?.open({
        zIndex: params.zIndex,
        name: `Renovação ${params.basketPlan?.getName()}`,
      });
      zIndex.value = params.zIndex ?? 1000;
      deliveryAreaId.value = params.basketPlan?.getDeliveryAreaId() ?? "";
      requestRenew.basketPlanId = params.basketPlan?.getId();
      requestRenew.basketName = params.basketPlan?.getName();
      basketValue.value = FormatUtils.formatMoney(FormatUtils.replaceDecimalSeparator(params.basketPlan?.getValue().toString() ?? ''));
      requestRenew.checkValue = false;
      requestRenew.deliveryType = undefined;
      requestRenew.basketValue = FormatUtils.formatMoney(FormatUtils.replaceDecimalSeparator(params.basketPlan?.getValue().toString() ?? ''));
      requestRenew.usersCount = params.basketPlan?.getUsersCount() ?? undefined;
      lastUpdateuser.value = params.basketPlan?.getUsersListUpdatedAt();
      basketPlan.value = params.basketPlan;
      await createBasketPlanStore.fetchBranches({
        deliveryAreaId: deliveryAreaId.value,
      });
      await promise;
    } catch (error) {
      console.error('An error occurred:', error);
    }
  },
  close: () => {
    createBasketPlanStore.$reset();
    clearModal();
    requestRenewBasketPlanModal.value?.close();
  },
});

function clearModal() {
  editValueComponent.value?.resetHasAlteration();
  requestRenew.$reset();
  createBasketPlanStore.$reset();
  requestRenewBasketPlanModal.value?.close();
}

async function requestRenewBasketPlan() {
  try {
    loadingModal?.value?.open();
    const res = await requestRenew.submit();
    if (res) clearModal();
  } finally {
    loadingModal?.value?.close();
  }
}

function goToScreen(params: {
  routeName: string;
}) {
  requestRenewBasketPlanModal.value?.minimize();
  router.push({
    name: params.routeName,
    params: {
      deliveryAreaId: deliveryAreaId.value
    }
  });
}

function goToPage(page: RequestRenewPages) {
  requestRenew.branchId = pickupBranchId.value ?? undefined;
  requestRenew.deliveryType = deliveryType.value!;
  const res = requestRenew.goToPage(page);
  if (res.getValue()) {
    requestRenewBasketPlanModal.value?.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }
}

const tomorrow = new Date(new Date().setDate(new Date().getDate() + 1));

function getMinDeliveryDate() {
  const limitDate = requestRenew.deliveryDateUtc ?? tomorrow;
  return new Date(new Date(limitDate).setDate(new Date(limitDate).getDate() + 1));
}

function closeAlteration() {
  const hasAlteration = editValueComponent.value?.getHasAlteration(); 
  if(hasAlteration?.value && !isMouseOver.value){
    editValueComponent.value?.resetHasAlteration();
  }
}

</script>

<template>
  <CommonModal :can-minimize="true" ref="requestRenewBasketPlanModal"  :is-full-screen="true" :minimized-data="{
    type: MinimizedType.BASKET,
  }">
    <!-- SELECT DELIVERY OPTIONS -->
    <section class="data" :style="{
      display: requestRenew.currentPage == RequestRenewPages.SELECT_DELIVERY_OPTIONS ? 'flex' : 'none',
    }"  @click="closeAlteration()" >
      <p class="title">Pedido de renovação de cesta</p>
      <div class="space-600 "></div>
      <div class="plan-name-value">
        <p class="plan-name">{{ requestRenew.basketName }}</p>
        <EditValueComponent ref="editValueComponent"
          :suportingText="requestRenew.balanceError ?? ''"
          @update:basketValue="(v) => requestRenew.basketValue = v"
          @reset:basketValue="(_) => requestRenew.basketValue = basketValue ?? ''"
          :basketValue="requestRenew.basketValue"
          @mouseenter="isMouseOver = true" @mouseleave="isMouseOver = false" ></EditValueComponent>
      </div>
      <div class="space-400 "></div>
      <div class="middle-content ">
        <StaffComponent @click="goToScreen({ routeName: routes.usersList.name })" title="Quadro de colaboradores"
          :last-update="lastUpdateuser"/>
        <CommonToggle label="Confirmo que o quadro de colaboradores deste plano está atualizado"
          name="autoGenDefaultBasket" :supportingText="requestRenew.collaboratorsError" :value="requestRenew.checkValue ?? false"
          @change="(_, value) => {requestRenew.checkValue = value; requestRenew.collaboratorsError = '' }" />
        <div class="space-800 "></div>
        <p class="information-text">Informações de entrega</p>
        <DeliverySelector ref="deliverySelector"
          :branchError="requestRenew.branchError"
          :deliveryError="requestRenew.deliveryError"
          :branch-addresses="(createBasketPlanStore.branchAddresses as BranchAddresses)"
          @update:delivery-type="deliveryType = $event"
          @update:pickup-branch-id="pickupBranchId = $event"
          @clean-delivery-error="requestRenew.deliveryError = $event ?? ''"
          @clean-branch-error="requestRenew.branchError = $event ?? ''"
          :z-index="zIndex ? zIndex + 1 : undefined">
        </DeliverySelector>
        <div class="date-info">
          <Datepicker v-model="requestRenew.deliveryDateUtc" :enable-time-picker="false"
            :min-date="getMinDeliveryDate()" placeholder="Data de entrega" :teleport="true" locale="pt-br"
            month-name-format="long" :day-names="FormatUtils.getDaysName(true)" format="dd/MM/yyyy"
            select-text="Selecionar" cancel-text="Cancelar" />
            <p class="supporting-text" v-if="requestRenew.dateError">{{ requestRenew.dateError }}</p>
        </div>
        <TextAreaInput class="custom-datepicker" label="Observações" state="default" type="text"
          :model-value="requestRenew.observation ?? ''" @update:model-value="(v) => requestRenew.observation = v"
          @tap-trailing="requestRenew.observation = ''" />
      </div>
      <div class="space-600 "></div>
      <div class="final-button">
        <CommonButton @click="clearModal" type="outlined" text="Cancelar" action="secondary"></CommonButton>
        <CommonButton @click="goToPage(RequestRenewPages.CONFIRMATION)" text="Enviar pedido"></CommonButton>
      </div>
    </section>

    <!-- CONFIRMATION -->
    <section class="data" :style="{
      display: requestRenew.currentPage == RequestRenewPages.CONFIRMATION ? 'flex' : 'none',
    }">
      <p class="title">Pedido de renovação de cesta</p>
      <div class="space-600 "></div>
      <div class="column-container d-flex w-100">
        <div class="container w-50">
          <BasketPlanCard v-if="basketPlan" :hide-controls="true" :basket-plan="(basketPlan as BasketPlan)" />
        </div>
        <div class="container">
          <div class="subcontainer">
            <p class="subtitle">Total por colaborador</p>
            <CurrencyValue class="margin-top-value" color="pink" :value="FormatUtils.parseFormattedNumber(requestRenew.basketValue) ?? 0"
              :fontReal="21" :fontSize="21" />
          </div>
          <div class="subcontainer">
            <p class="subtitle">Colaboradores no plano</p>
            <UsersCounter :count="requestRenew.usersCount ?? 0" :is-reverse="true" :has-size="21" :color="'pink'"
              :has-stroke="true" />
          </div>
          <div class="separator w-75"></div>
          <div class="subcontainer">
            <p class="subtitle">Total</p>
            <CurrencyValue class="margin-top-value" color="pink"
              :value="(FormatUtils.parseFormattedNumber(requestRenew.basketValue) ?? 0) * (requestRenew.usersCount ?? 0)" :fontReal="27"
              :fontSize="27" />
          </div>
        </div>
      </div>
      <div class="space-600 "></div>
      <PaymentMethodCard :payment-methods="[{ type: PaymentMethodTypes.TICKET }]"
        @change-method="(index) => console.log(index)"  />
      <div class="space-600"></div>
      <div class="final-button">
        <CommonButton @click="goToPage(RequestRenewPages.SELECT_DELIVERY_OPTIONS)" type="outlined" text="Voltar"
          action="secondary"></CommonButton>
        <CommonButton v-if="requestRenew.usersCount" @click="requestRenewBasketPlan"
          text="Confirmar pedido de renovação"></CommonButton>
      </div>
    </section>
  </CommonModal>
</template>

<style scoped>
.supporting-text {
  font-family: 'Montserrat';
  font-size: 12px;
  font-weight: 300;
  color: var(--components-input-error-supporting-text-color)
}

.information-text {
  color: #434049;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.margin-top-value {
  padding-top: 8px;
}

.custom-datepicker .datepicker .--dp-row-margin {
  margin: 10px 0;
}

.data {
  display: flex;
  flex-direction: column;
  padding: 32px;
  overflow-y: auto;
}

.final-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.space-400 {
  height: var(--semantic-spacing-inset-400, 32px);
}

.space-600 {
  height: var(--semantic-spacing-stack-600, 48px);
}

.space-800 {
  height: var(--semantic-spacing-stack-800, 64px);
}

.middle-content {
  display: flex;
  flex-direction: column;
  gap: var(--semantic-spacing-stack-200, 16px);
}

.size-24 {
  font-size: 24px;
}

.size-48 {
  font-size: 48px;
}

.plan-name {
  color: var(--semantic-color-gray-default, var(--semantic-color-gray-default, #434049));
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.title {
  text-align: start;
  font-weight: 700;
  font-size: 24px;
}

.date-info {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 4px;
}

.container {
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  gap: 16px;
  padding: 0px;
  min-width: 100px;
}

.subcontainer {
  display: flex;
  flex-direction: column;
  align-items: end;
  width: 100%;
}

.separator {
  height: 1px;
  border-top: 1px solid var(--token-input-disabled-border-color, #F2F1F3);
  align-self: flex-end;
}

.subtitle {
  display: flex;
  color: var(--option-color-gray-700, #5D5867);
  font-family: 'Montserrat';
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

@media screen and (max-width: 385px) {
  .final-button {
    gap: 8px;
    flex-direction: column;
    padding-bottom: 10px;
  }

  .text-checkbox-container {
    width: 100px;
  }

  .title {
    text-align: center;
    font-weight: 700;
  }

  .data {
    padding: 16px;
  }

  .modal-fullscreen-conten {
    align-self: flex-start;
    padding: 0;
  }

  .title {
    font-size: 20px;
  }

  .text-checkbox-container {
    font-size: 14px;
  }

  .plan-name {
    font-size: 16px;
  }
}

@media screen and (max-width: 475px) {
  .column-container {
    flex-direction: column;
  }

  .container {
    padding-bottom: 16px;
  }
}
</style>