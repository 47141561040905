import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "text-checkbox" }
const _hoisted_2 = { class: "text-checkbox-container" }
const _hoisted_3 = ["checked", "name"]
const _hoisted_4 = {
  key: 0,
  class: "label"
}
const _hoisted_5 = {
  key: 0,
  class: "supporting-text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("input", {
        type: "checkbox",
        checked: $props.value,
        class: "custom-checkbox",
        onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('change', $event, ($event.target as HTMLInputElement).checked))),
        name: $props.name
      }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_3),
      ($props.label)
        ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString($props.label), 1 /* TEXT */))
        : _createCommentVNode("v-if", true)
    ]),
    ($props.supportingText)
      ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString($props.supportingText), 1 /* TEXT */))
      : _createCommentVNode("v-if", true)
  ]))
}