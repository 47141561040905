import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-363449b9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "avatar-container" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($props.canEdit)
      ? (_openBlock(), _createElementBlock("i", {
          key: 0,
          class: "bi bi-pencil-square edit-avatar",
          onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.emit('on-edit')))
        }))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", {
      class: _normalizeClass(`avatar avatar-${$props.size}`)
    }, [
      ($props.item?.image)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            src: $props.item?.image
          }, null, 8 /* PROPS */, _hoisted_2))
        : _createCommentVNode("v-if", true),
      _createElementVNode("p", null, _toDisplayString($setup.getInitials($props.item?.name ?? '')), 1 /* TEXT */)
    ], 2 /* CLASS */)
  ]))
}