<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref } from 'vue';
import { Icons } from '../../font-awesome';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { DOMUtils } from '@/utils/dom_utils';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import CommonButton from '../CommonButton.vue';

const quadrant = ref({
  isSuperior: false,
  isLeft: false,
});

const props = defineProps<{
  hideBorder?: boolean;
  emptyMessage?: string;
  customSubtitle?: string;
  initialSelection?: number;
  size?: 'small',
  zIndex?: number;
  state?: 'disabled' | 'error' | 'default',
  supportingText?: string;
  icon?: IconDefinition;
  clearOption?: {
    title: string;
  },
  options: {
    title: string;
    subtitle?: string;
    disabled?: boolean;
  }[];
  noOptionsMessage?: string;
  button?: {
    icon: IconDefinition;
    text: string;
    click: (evt: Event) => void;
  }
  deprioritizeTitle?: boolean;
}>();

const selected = ref<number | null>(props.initialSelection ?? null);
const contextMenuElem = ref<HTMLDivElement>();
const labelElem = ref<HTMLElement | null>(null);
const currentLabelSize = ref(0);
const labelLeft = 20;
const floatOn = computed(() => isFocused.value && selected !== null);
const state = computed(() => props.state ?? 'default');
const isShowingContextMenu = ref(false);
const isFocused = ref(false);
const isHovered = ref(false);
const maxHeight = ref(0);
const isMobile = ref(false);

defineEmits<{
  (e: 'change', evt: Event, value: number | null): void
}>();

function getState() {
  if (isFocused.value) {
    return "focused";
  }
  if (isHovered.value && state.value != "disabled") {
    return "hovered";
  }
  return `${state.value}`;
}

function getTitle(selected:number | null):string | null {
  if (selected === null) return null;
  if (props.deprioritizeTitle) {
    if (props.customSubtitle) return `${props.customSubtitle}`;
    if (props.customSubtitle !== undefined) return '';
    return props.options?.at(selected)?.subtitle ?? null;
  }
  return props.options?.at(selected)?.title ?? null
}

function getSubTitle(selected:number | null):string | null {
  if (selected === null) return null;
  if (props.deprioritizeTitle) return props.options?.at(selected)?.title ?? null;
  if (props.customSubtitle !== undefined) return '';
  return props.options?.at(selected)?.subtitle ?? null;
}

defineExpose({
  clearSelection: () => {
    selected.value = null;
  },
  getSelected: () => {
    return selected.value;
  },
  setSelected: (value: number | null) => {
    selected.value = value;
  }
});

let resizeObserver: ResizeObserver | null = null;
onMounted(() => {
  window.addEventListener('resize', update);
  window.addEventListener('click', () => {
    if (!isMouseOver.value) isShowingContextMenu.value = false;
    if (!isShowingContextMenu.value) isFocused.value = false;
  });
  resizeObserver = new ResizeObserver(update);
  resizeObserver.observe(contextMenuElem.value!);
});

onUnmounted(() => {
  resizeObserver?.disconnect();
});

function update() {
  const contextMenuHeight = contextMenuElem.value?.getBoundingClientRect().height ?? 0;
  quadrant.value = DOMUtils.detectQuadrant(contextMenuElem.value!);
  maxHeight.value = DOMUtils.getDistanceToTopBottom(contextMenuElem.value!) - 5 - contextMenuHeight;
  isMobile.value = window.innerWidth < 836;
  currentLabelSize.value = labelElem.value?.offsetWidth ?? 0;
}

const isMouseOver = ref(false);

</script>

<template>
  <div
    class="custom-selector"
    v-if="options.length || noOptionsMessage || button"
    ref="contextMenuElem"
    @click="if (getState() !== 'disabled') {isShowingContextMenu = !isShowingContextMenu; update(); isFocused = !isFocused}"
    @mouseenter="isMouseOver = true"
    @mouseleave="isMouseOver = false"
    @contextmenu="(e) => e.stopPropagation()"
    :style="props.hideBorder ? { border: 'none', gap: '8px' } : {
      border: `1px solid var(--components-input-${getState()}-border-color, #CDC8D7)`,
      borderTop: selected !== null && emptyMessage ? `none` : `1px solid var(--components-input-${getState()}-border-color, #CDC8D7)`,
      minHeight: options.some(e => e.subtitle) && props.size != 'small'  ? `72px` : `53px`,
    }">
    <div v-if="selected !== null" class="content-info">
      <p :class="getTitle(selected) && props.options?.at(selected)?.subtitle ? 'sel-title-option' : 'sel-subtitle-option'">{{ getTitle(selected) }}</p>
      <p class="sel-subtitle-option" v-if="getSubTitle(selected)">{{ getSubTitle(selected) }}</p>
    </div>
    <div class="input-before" v-if="(selected !== null) && labelElem && emptyMessage" :style="{
      backgroundColor: `var(--components-input-${getState()}-border-color, #CDC8D7)`,
    }"></div>
    <div class="input-after" v-if="(selected !== null) && labelElem && emptyMessage" :style="{
      backgroundColor: `var(--components-input-${getState()}-border-color, #CDC8D7)`,
      width: `calc(100% - ${labelLeft}px - (var(--semantic-border-radius-default, 7px) * 2) - ${currentLabelSize}px)`,
    }"></div>
    <div ref="labelElem" v-if="emptyMessage"
      :class="[
        'semantic-typography-body-regular-default',
        'input-field-label',
        {
          'input-field-label-focused': floatOn || selected !== null,
          'semantic-typography-body-bold-small': floatOn
        }
      ]"
      :style="{
        color: `var(--components-input-${getState()}-label-color, #6E6979)`,
        left: labelLeft,
        top: selected !== null ? `0%` : `50%`,
      }">
      <FontAwesomeIcon v-if="icon" :icon="icon" />
      <p style="font-size: 14px;">
        {{ emptyMessage }}
      </p>
    </div>
    <p class="semantic-typography-body-regular-default input-field-label" v-if="!emptyMessage && selected === null"
      :style="{
        color: `var(--components-input-${getState()}-label-color, #6E6979)`,
        left: labelLeft,
      }">
      {{ 'Selecionar item' }}
    </p>
    <div></div>
    <FontAwesomeIcon
      class="arrow-icon"
      :style="{
        transform: `rotate(${isShowingContextMenu ? -180 : 0}deg)`,
        color: `var(--components-input-${getState()}-border-color, #CDC8D7)`
      }"
      :icon="Icons.imported.faChevronDown"
    />

    <Teleport to="body" :disabled="!isMobile">
      <div
        class="context-menu"
        v-show="isShowingContextMenu"
        :style="{
          zIndex: props.zIndex ?? 100000,
          top: quadrant.isSuperior ? `${contextMenuElem?.getBoundingClientRect().height}px` : 'unset',
          bottom: quadrant.isSuperior ? 'unset' : `${contextMenuElem?.getBoundingClientRect().height}px`,
          left: quadrant.isLeft ? '0' : 'unset',
          right: quadrant.isLeft ? 'unset' : '0',
          maxHeight: maxHeight + 'px',
        }"
      >
        <div
          class="options-father"
          :style="{
            height: isMobile ? '50vh' : 'unset',
          }"
        >
          <div class="option" @click="$emit('change', $event, null); selected = null;" v-if="clearOption && selected !== null">
            <p class="sel-title-option">{{ clearOption.title ?? 'Limpar'}}</p>
          </div>
          <div class="option" v-for="(option, index) in options" @click="(e) => {
            $emit('change', e, index);
            selected = index;
          }" :key="index" v-show="!option.disabled">
            <p class="sel-title-option">{{ option.title }}</p>
            <p class="sel-subtitle-option">{{ option.subtitle }}</p>
          </div>
          <p class="sel-empty-message" v-if="!options.length && noOptionsMessage">{{ noOptionsMessage }}</p>
          <div v-if="button && (options.length || noOptionsMessage)" style="height: 12px;"></div>
          <CommonButton
            v-if="button"
            @click="button?.click"
            :icon="button.icon"
            action="secondary"
            type="outlined"
            class="option-button"
            :text="button.text"
          ></CommonButton>
        </div>
      </div>
    </Teleport>
  </div>
  <p class="supporting-text" :style="`color: var(--components-input-${getState()}-supporting-text-color)`"
      v-if="supportingText">
      {{ supportingText }}
    </p>
</template>

<style scoped>
.supporting-text {
  font-family: 'Montserrat';
  font-size: 12px;
  font-weight: 300;
  padding-top: var(--semantic-spacing-stack-100, 8px);
}

.custom-selector {
  color: var(--token-input-default-text-color);
  border-radius: var(--semantic-border-radius-default, 7px);
  background: var(--components-input-default-background-color, rgba(0, 0, 0, 0.00));
  display: flex;
  justify-content: space-between;
  padding: var(--semantic-spacing-inset-200, 16px) var(--semantic-spacing-inset-300, 24px);
  align-items: center;
  gap: var(--semantic-spacing-stack-400, 32px);
  cursor: pointer;
  position: relative;
  width: 100%;
  border-top: none;
}

.content-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2px;
  transition: 0.3s ease all;
}

.sel-title {
  color: var(--semantic-color-fg-muted, #6E6979);
  text-align: center;
  font-family: 'Montserrat';
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.sel-subtitle {
  color: var(--semantic-color-gray-subtle, var(--semantic-color-gray-subtle, #6E6979));
  text-align: left;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Montserrat';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  word-wrap: wrap;
}

.context-menu {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  gap: 10px;
  border-radius: 7px;
  background: #FFF;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.10);
  width: fit-content;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 200px;
  max-width: 100vw;
  padding-top: 10px;
  cursor: initial;
}

.options-father {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  gap: 10px;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.option {
  display: flex;
  width: 400px;
  max-width: 100vw;
  flex-direction: column;
  padding: 24px var(--semantic-spacing-stack-400, 32px);
  align-items: flex-start;
  gap: 2px;
  border-radius: 7px;
  border: 1px solid #F2F1F3;
  background: #FFF;
  cursor: pointer;
}

.option:hover {
  background: #F2F1F3;
}

@media screen and (max-width: 836px) {
  .context-menu {
    position: fixed!important;
    top: 0!important;
    bottom: unset!important;
    left: 0!important;
    right: unset!important;
    width: 100vw!important;
    height: 100vh!important;
    min-width: unset!important;
    max-width: unset!important;
    min-height: unset!important;
    max-height: unset!important;
    border-radius: 7px 7px 0px 0px!important;
    padding: 0;
    padding-top: 50vh;
    background-color: #0006;
    border-radius: none;
    overflow-y: auto;
  }

  .options-father {
    border-radius: 7px 7px 0px 0px;
    background: #FFF;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.10);
  }

  .option {
    width: 100%!important;
  }
}

.arrow-icon {
  font-size: 12px;
  color: var(--components-input-default-right-icon-color, #6E6979);
  transition: 0.3s ease all;
}

.sel-title-option {
  color: #434049;
  font-family: 'Montserrat';
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.sel-subtitle-option {
  color: var(--components-input-default-text-color, #CDC8D7);
  font-family: 'Montserrat';
  font-size: 16px;
  font-style: normal;
  font-weight: var(--components-input-default-font-weight, 800);
  line-height: normal;
}

.main-icon {
  color: var(--option-color-gray-700, #AAA4B6);
  font-size: 14px;
  width: 14px;
}

.sel-empty-message {
  color: var(--semantic-color-fg-muted, #6E6979);
  font-family: 'Montserrat';
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-align: center;
  margin: 0 auto;
  margin-top: 24px;
}

.option-button {
  align-self: center;
}

.input-after,
.input-before {
  position: absolute;
  top: 0;
  height: 1px;
}

.input-before {
  left: calc(var(--semantic-border-radius-default, 7px) / 2);
  width: 10px;
}

.input-after {
  right: calc(var(--semantic-border-radius-default, 7px) / 2);
}

.input-field-label {
  position: absolute;
  left: 21px;
  top: 50%;
  line-height: 16px;
  transition: all 0.1s;
  pointer-events: none;
  display: flex;
  padding: 0px var(--semantic-spacing-stack-025, 2px);
  gap: var(--semantic-spacing-inline-100, 8px);
  align-items: center;
  transform: translate(0%, -50%);
}

.input-field-label-focused {
  position: absolute;
  top: 0%;
  font-size: 12px;
  gap: var(--semantic-spacing-inline-100, 4px);
}

</style>