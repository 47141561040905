<script setup lang="ts">
import { defineProps, defineEmits, ref } from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { PaymentMethodTypes, PaymentMethodUtils } from '@/models/payment_method/payment_method_types_enum';
import { PaymentMethod } from '@/models/payment_method/payment_method_model';

const props = defineProps<{
  paymentMethods: {
    type: PaymentMethodTypes,
    msg?: string,
  }[],
}>();

const emits = defineEmits<{
  (e: 'change-method', value: number | null): void,
}>();

const selected = ref<PaymentMethod | null>(null);

function getMethods() {
  const methods: PaymentMethod[] = props.paymentMethods.map(e => {
    return new PaymentMethod({
      type: e.type,
      name: PaymentMethodUtils.getName(e.type),
      icon: PaymentMethodUtils.getIcon(e.type),
      msg: PaymentMethodUtils.getMessage(e.type),
    });
  });
  if (methods.length === 1) {
    selected.value = methods[0];  
    emits('change-method', methods[0].type);
  }
  return methods;
}

</script>

<template>
  <div class="middle-content">
    <p class="information-text">Método de pagamento</p>
    <div class="container">
      <div v-for="method in getMethods()" class="card" :class="{selected: method.type == selected?.type}"
        v-on:click="() => {
          selected = method;
          $emit('change-method', method.type);
        }">
        <div class="icon">
          <FontAwesomeIcon
            :icon="method.icon"
            :size="'3x'"
          />
          <p class="title">{{ method.name }}</p>
        </div>
      </div>
    </div>
    <p class="information-method" v-if="selected && selected.msg">{{ selected?.msg }}</p>
  </div>
</template>

<style scoped>

.container {
  display: grid;
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: repeat(auto-fill, minmax(172px, 1fr));
  grid-gap: 10px;
}

.card {
  border: 1px solid var(--semantic-color-neutral-border-subtle, #F2F1F3);
  padding: 10px;
  text-align: center;
  min-width: 172px;
  border-radius: 16px;
}

.selected {
  border: 1px solid var(--semantic-color-primary-default, #8300C7);
}

.card .icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 172px;
  gap: 12px;
  color: var(--semantic-color-fg-muted, #6E6979);
}

.card .icon .title {
  color: var(--semantic-color-fg-muted, #6E6979);
  text-align: center;
  font-family: 'Montserrat';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.card:hover {
  cursor: pointer;
}

.information-text {
  color: var(--semantic-color-fg-default, #5D5867);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.middle-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: var(--semantic-spacing-stack-200, 16px);
}

.information-method {
  color: var(--semantic-color-fg-muted, #6E6979);
  text-align: start;
  font-family: 'Montserrat';
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

@media screen and (max-width: 425px) {
  .card {
    max-width: 172px;
  }
}

</style>