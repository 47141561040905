<script setup lang="ts">
import CustomSelector from '@/components/custom_selector/CustomSelector.vue';
import { CityUtils } from '@/utils/city_utils';
import { StateUtils } from '@/utils/state_utils';
import { computed, ref } from 'vue';

const citySelector = ref<InstanceType<typeof CustomSelector>>();

const props = defineProps<{
  cityError?: string;
}>();

const stateCode = ref<string>('');

defineEmits<{
  (e: 'change', city: string | null): void
}>();

defineExpose({
  setState(stateName: string) {
    stateCode.value = stateName.length > 2 ? StateUtils.getStateCodeByFullName(stateName) ?? '' : stateName;
    citySelector.value?.clearSelection();
  },
  setCity(cityName: string) {
    const cityIndex = CityUtils.findCityIndexByName(stateCode.value, cityName);
    if (cityIndex < 0) citySelector.value?.clearSelection();
    else citySelector.value?.setSelected(cityIndex);
  },
  setSelected(stateName: string, cityName: string) {
    this.setState(stateName);
    this.setCity(cityName);
  },
  clearSelection() {
    citySelector.value?.clearSelection();
  }
});

</script>

<template>
  <CustomSelector
    :z-index="100000000000"
    :state="cityError ? 'error' : stateCode ? 'default' : 'disabled'"
    :supportingText="cityError ?? undefined"
    ref="citySelector"
    empty-message="Cidade"
    :options="stateCode ? CityUtils.cities[stateCode].map(city => ({title: city})) : [{title: ''}]"
    @change="(_, index) => $emit('change', CityUtils.getCityNameByIndex(stateCode, index ?? -1))"
  ></CustomSelector>
</template>

<style scoped></style>