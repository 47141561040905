<script setup lang="ts">
import { Ref, ref, inject, defineExpose } from 'vue';
import { AlertModalInjection, LoadingModalInjection } from '@/infra/constants/injections';
import CommonModal from './CommonModal.vue';
import LoadingModal from '@/components/modals/LoadingModal.vue';
import AlertModal from '@/components/modals/AlertModal.vue';
import CommonInput from '@/components/CommonInput.vue';
import CommonButton from '@/components/CommonButton.vue';
import AvatarCard from '@/components/AvatarCard.vue';
import { useCreateBranch } from '@/stores/settings/create_branch_store';
import { BranchAddresses } from '@/models/branch_addresses_model';
import CommonToggle from '@/components/CommonToggle.vue';
import AddressSelector from '../custom_selector/AddressSelector.vue';
import StateSelector from '../custom_selector/StateSelector.vue';
import CitySelector from '../custom_selector/CitySelector.vue';

const createBranchModal = ref<InstanceType<typeof CommonModal>>();
const alertModal = inject<Ref<InstanceType<typeof AlertModal>>>(AlertModalInjection);
const loadingModal = inject<Ref<InstanceType<typeof LoadingModal>>>(LoadingModalInjection);
const addressSelector = ref<InstanceType<typeof AddressSelector>>();
const stateSelector = ref<InstanceType<typeof StateSelector>>();
const citySelector = ref<InstanceType<typeof CitySelector>>();

const createBranch = useCreateBranch();

const onCreateMethod = ref<((newBranch: BranchAddresses | null) => void) | null>(null);

defineExpose({
  open: async (params?: {
    zIndex?: number;
    onCreate?: (newBranch: BranchAddresses | null) => void;
  }) => {
    addressSelector.value?.clearOptions();
    stateSelector.value?.setSelected(createBranch.address.getState());
    citySelector.value?.setSelected(createBranch.address.getState(), createBranch.address.getCity());
    onCreateMethod.value = params?.onCreate ?? null;
    await createBranchModal.value?.open({
      zIndex: params?.zIndex ?? 2,
    });
  },
  close: () => {
    clearAll();
    createBranchModal.value?.close();
  },
});

async function createNewBranch() {
  try {
    loadingModal?.value?.open({ zIndex: 1000 });
    const res = await createBranch.submit();
    if (!res) return;
    if (res.isError()) alertModal?.value?.open({ title: 'Erro', content: res.getError() ?? 'Erro desconhecido' });
    else {
      onCreateMethod.value?.call(null, res.getValue() ?? null);
      clearAll();
      createBranchModal.value?.close();
    }
  } finally {
    loadingModal?.value?.close();
  }
}

async function avatarEdit() {
  return null;
}

function clearAll() {
  addressSelector.value?.clearOptions();
  stateSelector.value?.clearSelection();
  citySelector.value?.clearSelection();
  createBranch.$reset();
}

</script>

<template>
  <CommonModal ref="createBranchModal" :is-full-screen="true">
    <main class="container">
      <header class="header">
        <h1 class="title">Cadastrar nova sede</h1>
      </header>
      <form class="form">
        <div class="form-header d-flex align-items-center">
          <AvatarCard :item="{
            name: createBranch.getName(),
            image: createBranch.getImgUrl()
          }" :size="'large'" @on-edit="avatarEdit" />
          <CommonInput label="Nome" class="w-100" :state="createBranch.errors.branchNameError ? 'error' : 'default'"
            :supporting-text="createBranch.errors.branchNameError ?? undefined" :model-value="createBranch.branchName"
            @update:model-value="createBranch.branchName = $event; createBranch.errors.branchNameError = null;"
            @tap-trailing="createBranch.branchName = ''" />
        </div>
        <AddressSelector ref="addressSelector" :model-value="createBranch.address.getCep()"
          @update:model-value="createBranch.address.setCep($event);"
          @select="stateSelector?.setSelected($event.getState()); citySelector?.setSelected($event.getState(), $event.getCity()); createBranch.setAddress($event);"
          label="CEP" class="w-30" placeholder="CEP" :fetchFunction=createBranch.fetchAddressByCEP />
        <div class="form-section">
          <div style="flex: 0 0 30%">
            <StateSelector ref="stateSelector" :state-error="createBranch.errors.stateError ?? undefined"
              @change="createBranch.address.setState($event ?? ''); citySelector?.setState($event ?? ''); createBranch.errors.stateError = null" />
          </div>
          <CitySelector ref="citySelector" :city-error="createBranch.errors.cityError ?? undefined"
            @change="createBranch.address.setCity($event ?? ''); createBranch.errors.cityError = null" />
        </div>
        <div class="form-section">
          <CommonInput label="Bairro" style="flex: 0 0 30%" class="w-35"
            :state="createBranch.errors.vicinityError ? 'error' : 'default'"
            :supporting-text="createBranch.errors.vicinityError ?? undefined"
            :model-value="createBranch.address.getVicinity()"
            @update:model-value="createBranch.address.setVicinity($event); createBranch.errors.vicinityError = null;"
            @tap-trailing="createBranch.address.setVicinity('')" />
          <CommonInput label="Endereço" class="w-100" :state="createBranch.errors.streetError ? 'error' : 'default'"
            :supporting-text="createBranch.errors.streetError ?? undefined"
            :model-value="createBranch.address.getStreet()"
            @update:model-value="createBranch.address.setStreet($event); createBranch.errors.streetError = null;"
            @tap-trailing="createBranch.address.setStreet('')" />
        </div>
        <div class="form-section">
          <CommonInput mask="#####" style="flex: 0 0 30%"
            :state="createBranch.errors.streetNumberError ? 'error' : createBranch.hasNumber ? 'default' : 'disabled'"
            :supporting-text="createBranch.errors.streetNumberError ?? undefined" label="Número" class="w-35"
            :model-value="createBranch.address.getStreetNumber()"
            @update:model-value="createBranch.address.setStreetNumber($event); createBranch.errors.streetNumberError = null;"
            @tap-trailing="createBranch.address.setStreetNumber('')" />
          <CommonInput label="Complemento" class="w-100" :model-value="createBranch.address.getComplement()"
            @update:model-value="createBranch.address.setComplement($event)"
            @tap-trailing="createBranch.address.setComplement('')" />
        </div>
        <div class="toggle">
          <CommonToggle label="Endereço sem número" name="autoGenDefaultBasket" :value="!createBranch.hasNumber"
            @change="(_, value) => { createBranch.hasNumber = !value; createBranch.address.setStreetNumber('') }" />
        </div>
      </form>
      <footer class="footer">
        <CommonButton type="outlined" border-radius="small" action="secondary"
          @click="() => { createBranchModal?.close(); clearAll(); }" text="Cancelar cadastro"></CommonButton>
        <CommonButton type="filled" border-radius="small" @click="createNewBranch()" text="Confirmar cadastro"
          :action="`${createBranch.canCreate() ? 'cta' : 'disabled'}`"></CommonButton>
      </footer>
    </main>
  </CommonModal>
</template>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  gap: 48px;
}

.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.title {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--option-color-gray-800, #434049);
  font-size: 24px;
  font-weight: 800;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.form-header {
  gap: 24px;
  margin-bottom: 32px;
}

.form-section {
  display: flex;
  gap: 16px;
}

.context-menu {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  gap: 10px;
  border-radius: 7px;
  background: #FFF;
  color: var(--components-input-default-text-color, #CDC8D7);
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.10);
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  top: 177px;
  max-height: 200px;
  max-width: 100vw;
  padding-top: 10px;
  cursor: initial;
  z-index: 100000;
}

.toggle {
  padding: 4px 0px;
}

.footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 8px;
  margin-top: 16px;
}

.footer button {
  width: fit-content;
}
</style>
