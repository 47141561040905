import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-086640f0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text-checkbox-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($props.icon)
      ? (_openBlock(), _createBlock($setup["FontAwesomeIcon"], {
          key: 0,
          icon: $props.icon,
          class: "icon"
        }, null, 8 /* PROPS */, ["icon"]))
      : _createCommentVNode("v-if", true)
  ]))
}