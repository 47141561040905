import { BasketPlan } from "@/models/basket_plans/basket_plan_model";
import { ValueResult } from "@/models/value-result";
import { JsonSerializer } from "typescript-json-serializer";
import BffFoodPassBackofficeApiRequester from "../bff_backoffice_api_requester";
import { RenewRequest } from "@/models/request-renew/request_renew_model";


export class RequestRenewBFFBackofficeModule {

  static readonly controller = "request-renew-basket-plan";
  static readonly serializer = new JsonSerializer();

  static async requestRenewBasketPlan(
    params: {
    renewRequest:RenewRequest[]
  }): Promise<ValueResult<boolean | null> | boolean> {
    try {
      const renewRequest = this.serializer.serialize(params.renewRequest);
      const res = await BffFoodPassBackofficeApiRequester.getAxios().post(`/${this.controller}/request/renew`, {
        renewRequest:renewRequest
      });
      return true;
    } catch (e: any) {
      return ValueResult.fromError(e);
    }
  }

}